body {
  letter-spacing: 0.5px;
}
.homebanner-c {
  position: absolute;
  top: -33px;
  width: 100%;
  object-fit: cover;
  left: 0px;

  @media (max-width: 820px) and (min-width:576px) {
    top: -20px !important;
  }
  @media (max-width: 575px) {
    top: -8px !important;
  }
}
.homevectorbottom {
  position: absolute;
  transform: rotate(180deg);
  bottom: -33px;
  z-index: 1;
  width: 100%;
  @media (max-width: 820px) and (min-width:576px) {
    bottom: -20px !important;
  }
  @media (max-width: 575px) {
    bottom: -8px !important;
  }
}
.zindex9 {
  z-index: 9;
}
.featurerbottom {
  position: absolute;
  bottom: -168px;
  width: 100%;
  height: 196px;
  @media (max-width: 575px) {
    bottom: -44px !important;
    height: 52px !important;
  }
}
.testimonaltop {
  position: absolute;
  top: -168px;
  width: 100%;

  left: 0px;
  transform: rotate(180deg);
  height: 196px;
}
.testimonalbottom {
  position: absolute;
  transform: rotate(180deg);
  bottom: -168px;
  width: 100%;
  z-index: 1;
  height: 196px;
  @media (max-width: 575px) {
    bottom: -173px !important;
  }
}
.fit2goAsia-text {
  position: absolute;
  top: 170px;
}
.fit2goAsialeft-text {
  position: absolute;
  bottom: 0px;
  left: -670px;
  z-index: 1;
}
.gallerybottombottom {
  position: absolute;
  transform: rotate(180deg);
  bottom: -48px;
  z-index: 1;
  width: 100%;
  @media (max-width: 575px) {
    bottom: -8px !important;
  }
}
.wh140px {
  width: 140px;
  height: 140px;
}
.inputsearch {
  background: transparent;
  border: 1px solid white;
  border-radius: 999px;
  width: 100%;
  padding: 8px;
}
.top11 {
  top: 11px;
}
.edit_icon {
  background: #8f8f8f;
  color: #fff;
}
//feed
.p1020 {
  padding: 8px 15px;
}
.maxheight380 {
  max-height: 380px !important;
}
.maxheight500 {
  max-height: 500px !important;
}
