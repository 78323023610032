//colors
.bg-primary {
  background-color: var(--chakra-colors-primary) !important;
}
.text-primary {
  color: var(--chakra-colors-primary) !important;
}

.text-danger {
  color: #e53e3e !important;
}

.text-warning {
  color: #ffb800;
}

.text-success {
  color: #0fba2a;
}

.bg-secondary {
  background-color: var(--chakra-colors-secondary) !important;
}
.bg-success {
  background-color: #0fba2a !important;
}
.text-secondary {
  color: var(--chakra-colors-secondary) !important;
}

.bg-tertiary {
  background-color: var(--chakra-colors-tertiary) !important;
}
.text-tertiary {
  color: var(--chakra-colors-tertiary) !important;
}
.clr-606060 {
  color: #606060;
}
.clr-7b7b7b {
  color: #7b7b7b;
}
.bggradient-mid {
  background: radial-gradient(
    circle,
    var(--chakra-colors-primary) 3%,
    var(--chakra-colors-secondary) 70%
  );
}
.bggradient-50 {
  background: radial-gradient(
    circle,
    var(--chakra-colors-primary) 0%,
    var(--chakra-colors-secondary) 40%
  );
}
.bggradient-left {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-secondary) 62%,
    var(--chakra-colors-primary) 99%
  );
}
.bggradient-right30 {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-primary) 0%,
    var(--chakra-colors-secondary) 30%
  );
}
.bggradient-left30 {
  background: linear-gradient(
    234deg,
    var(--chakra-colors-secondary) 65%,
    var(--chakra-colors-primary) 100%
  );
}

//font
// @font-face {
//   font-family: 'poppins-regular';
//   src: url('../fonts/poppins/Poppins-Regular.ttf') format('opentype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// small,
// label,
// span,
// li,
// button {
//   font-family: 'poppins-regular' !important;
// }

// body {
//   font-family: 'poppins-regular' !important;
// }
body {
  color: #353535;
}

.text333 {
  color: #333333;
}

.text353535 {
  color: #353535;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: var(--chakra-colors-primary);
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

.textwhite {
  color: white !important;
}

.lheight {
  line-height: 68px;
}

.trans-btn {
  background: inherit !important;
  color: #fff !important;
  border: 1px solid #fff;
  border-radius: 0px !important;
}

.text030303 {
  color: #030303;
}

.bg-f4f5f6 {
  background-color: #f4f5f6;
}

// .cardimage {
//   background-image: url(../images//CardBg.svg);
// }
.text8787 {
  color: #878787;
}

.cardbgset {
  object-fit: contain !important;
  position: relative;
  top: -49px;
  height: 322px;
  width: 100%;
  left: 0px;
}

.banner-height {
  height: 505px;

  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.banner-height2 {
  height: 605px;

  @media (max-width: 640px) {
    height: 290px !important;
  }
}

.chua-img {
  width: 100% !important;
  height: 450px !important;

  @media (max-width: 640px) {
    height: 250px !important;
  }
}

.col-62 {
  color: #626262 !important;
}

.cust-shadow {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
}
.smcust-shadow {
  @media (max-width: 575px) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25) !important;
  }
}
@media (max-width: 640px) {
  .sm-px-30px {
    padding: 30px !important;
  }

  .sm-fsize16px {
    font-size: 16px !important;
  }

  .sm-fsize14 {
    font-size: 14px !important;
  }

  .sm-fsize12 {
    font-size: 12px !important;
  }

  .sm-h-screen {
    height: 100% !important;
  }

  .trans-btn {
    font-size: 14px !important;
  }

  .sm-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .sm-btntextwh {
    font-size: 14px !important;
    width: 150px !important;
    height: 45px !important;
  }

  .lheight {
    line-height: 42px;
  }

  .sm-none {
    display: none !important;
  }

  .sm-h250px {
    height: 250px !important;
  }

  .banner-height {
    height: 180px !important;
  }

  .cust-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0.5rem;
  }
}

@media (max-width: 1024px) and (min-width: 641px) {
  .cust-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-fsize14 {
    font-size: 14px !important;
  }

  .tb-wfull {
    width: 100% !important;
  }

  .cust-grid4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0.5rem;
  }

  .tb-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .lheight {
    line-height: 48px !important;
  }

  .banner-height {
    height: 280px;
  }

  .tb-fsize23 {
    font-size: 23px !important;
  }

  .tb-h280px {
    height: 280px !important;
  }

  .md-h200px {
    height: 200px !important;
  }

  .md-h-32px {
    height: 32px !important;
  }

  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

@media (min-width: 1024px) {
  .abstop {
    position: absolute !important;
    top: 0 !important;
    border-radius: 20px !important;
  }
}

.menuHover:hover {
  font-weight: 700;
  border-bottom: 2px solid var(--chakra-colors-primary);
}

.w-230px {
  width: 230px;
}

.clr-d9d9d9 {
  color: #d9d9d9;
}

.bg-d9d9d9 {
  background-color: #d9d9d9;
}

.clr-5a5a5a {
  color: #5a5a5a;
}

.clr-fff {
  color: #fff;
}

.lh24px {
  line-height: 24px;
}

.lineClam4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.lineClamp2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.input-email {
  border-radius: 30px;
  border: 1px solid #d9d9d9;
  padding: 0px 6px 0px 26px;
  width: 100%;
  height: 48px;
}

.submit-email {
  padding: 11px 38px;
  border-radius: 30px;
}

span.chakra-button__icon.css-1hzyiq5 {
  margin-left: 0px !important;
}

.css-r6z5ec {
  width: 100% !important;
}

.phoneinput .form-control {
  width: 100% !important;
  padding: 0.5rem 14px 0.5rem 50px !important;
}

.react-tel-input .country-list {
  width: 460px !important;
}

.verifyemail {
  background: #6a2c70;
  padding: 3px 10px;
  border-radius: 5px;
  color: #fff;
  top: 16px;
  cursor: pointer;
}

.resendotp-t [type="text"] {
  border: 1px solid #a6adb778;
  font-size: 14px;
  border-radius: 6px;
  width: 40px !important;
  height: 40px !important;
}

.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}

.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 300px;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border-radius: 3px 0 0 3px;
}

.textred {
  color: red;
}

.custcolor:hover {
  color: var(--chakra-colors-primary) !important;
}

@media (max-width: 640px) {
  .mob-hidden {
    display: none;
  }
}

@media (min-width: 641px) {
  .web-hidden {
    display: none;
  }
}

.custfixed {
  position: fixed;
  z-index: 9999;
}

.navbar {
  height: 60px;
  background-color: #fef7e5;
  position: relative;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 821px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  .md-w-60px {
    width: 60px !important;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 59px;
    background-color: #fff;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 260px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}

.cutimage.chakra-wrap__listitem {
  margin: auto !important;
}

.flecol .chakra-wrap__list {
  display: flex;
  align-items: center;
}

@media (max-width: 640px) {
  .custcolrev {
    flex-direction: column-reverse;
  }
}

.css-r6z5ec {
  width: 0% !important;
}

// dropdown css

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  right: 0px;
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.br-40px {
  border-radius: 40px;
}

.border-6px {
  border: 6px solid #fff;
}

.hero-header {
  font-size: 60px;
  line-height: 70px;
  white-space: normal;
  font-weight: 900;
}

// height
.h-680px {
  height: 680px;
}

.h-580px {
  height: 580px;
}

.h-450px {
  height: 450px;
}

.h-400px {
  height: 400px;
}

.h-300px {
  height: 300px;
}

.h-280px {
  height: 280px;
}

.padresponsive .marquee {
  display: flex !important;
  justify-content: space-evenly !important;
}

@media (max-width: 640px) {
  .padresponsive {
    padding-top: 70px;
  }
}

.img-custom {
  width: 100%;
  height: 100px;
}
.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.wid-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.marque2 {
  top: -70px;
  height: 160px;
  right: auto;
  bottom: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 25px 10px;
  padding: 40px 0px;
  background-color: white;
  border-radius: 8px;
}

.pb-180px {
  padding-bottom: 180px;
}

.card {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  border: 0px solid #ddd9d8;
}
.card:hover {
  border-top: 10px solid #ddd9d8;
}

.card-img {
  width: 60px;
  height: 60px;
}

.card-img2 {
  border: 0px solid #ddd9d8;
  border-radius: 16px;
  height: 350px;
  width: 100%;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 6px,
    rgb(225, 225, 225) 0px 0px 0px 7px;
}

.card-img2:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
  border-bottom: 10px solid #ddd9d8;
}

.testimonial-card {
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.testimonial-div {
  width: 90px;
  height: 80px;
}
.testimonial-img {
  width: 70px;
  height: 70px;
}

.testimonial-card {
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.zoom {
  transition: all 0.5s ease-in-out;
}
.zoom:hover {
  transform: scale(1.1);
}

.overflow-hidden {
  overflow: hidden !important;
}

.bg-ddd9d8 {
  background-color: #ddd9d8;
}

.bg-a2a2a2 {
  background-color: #a2a2a2;
}

.clr-04080C {
  color: #04080c;
}
.clr-ddd9d8 {
  color: #ddd9d8;
}

.br-ddd9d8 {
  border-color: #ddd9d8;
}

.bg-d2d2d2 {
  background-color: #d2d2d2;
}

.bg-EDF2F6 {
  background-color: #edf2f6;
}

.bg-c2bfbe {
  background-color: #c2bfbe;
}

.box-shadow-inset {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 4px inset;
}

.box-shadow-outer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 4px;
}

.shadow-ffffff {
  box-shadow: 0 0px 0 6px #ffffff, 0 0px 0 7px #ddd9d8;
}

.shadow-ffffff:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

.custShadow {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.custShadow1 {
  box-shadow: rgba(0, 0, 0, 0.08) 2px 2px 18px 0px;
}

.custShadow1:hover {
  border-width: 8px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 12px 8px;
}

.custShadow2 {
  box-shadow: rgba(0, 0, 0, 0.21) 0px 0px 6px 0px;
}

.fsize86 {
  font-size: 86px;
  line-height: 110px;
}

.contactcss {
  background-color: #fff;
  padding: 2rem;
  border-radius: 16px;
}

.letter-spacing4px {
  letter-spacing: 4px;
}

.inputFeild {
  padding: 6px 16px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  border-bottom: 1px solid #e4e8ec;
  outline: none;
  margin: 0px;
  background-color: #f4f5f6;
  font-size: 14px;
}

.inputFeild1 {
  padding: 6px 16px;
  width: 100%;
  height: 60px;
  font-weight: 500;
  border: 2px solid #e4e8ec;
  outline: none;
  margin: 0px;
  background-color: #ddd9d8;
  font-size: 14px;
}

.form-button {
  color: #f7fbff;
  background-color: #c2bfbe;
}

.hover-6f6f6f:hover {
  border-left: 10px solid #6f6f6f;
}

.hover-ddd9d8:hover {
  border-left: 10px solid #ddd9d8;
}

.teams2-img {
  width: 200px;
  height: 200px;
}

.border-top12px {
  border-top: 12px solid #c2bfbe;
}

.br-28px {
  border-radius: 28px;
}

.br-16px {
  border-radius: 16px;
}

.w-h-330px {
  width: 380px;
  height: 330px;
}

.br-2px {
  border-width: 2px;
}
.br-2px:hover {
  border-color: #ddd9d8;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.numb-otp input {
  border: 1px solid gray;
  margin-right: 10px;
  width: 40px !important;
  height: 40px !important;
  border-radius: 6px;
}

.fsize56 {
  font-size: 56px;
  line-height: 66px;
}

.leading-12 {
  line-height: 48px;
}

.back-img-div {
  border-top: 20px solid #c2bfbe;
  position: static;
  border-radius: 16px;
}

.back-img {
  width: 400px;
  height: 578px;
  border-radius: 0px;
  margin-top: -10px;
}

.front-img-div {
  inset: auto auto auto 135px;
  width: 314px;
  height: 438px;
  display: block;
}

.front-img {
  width: 314px;
  height: 438px;
  z-index: 1;
  border-radius: 16px;
  border-color: #ffffff;
  box-shadow: rgb(255, 255, 255) 4px 0px 0px 8px;
}

.fsize60 {
  font-size: 60px;
}

.fsize64 {
  font-size: 64px;
  line-height: 72px;
}

.hr {
  padding-bottom: 4px;
  border-top: 1px solid #e4e8ec;
  background-color: #c2bfbe;
  margin-bottom: 24px;
  width: 20%;
  opacity: 90;
  min-height: 10px;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 999999999 !important;
}
.topminus35 {
  margin-top: -73px !important;
}
.filter35 {
  margin-top: -73px !important;
}
.clr-9c9c9c {
  color: #9c9c9c;
}

.minh450px {
  @media (min-width: 576px) {
    height: 450px;
  }
}
.minh500px {
  @media (min-width: 576px) {
    height: 500px;
  }
}
.bgg {
  background-color: rgba(255, 255, 255, 0.3);
}

.red-circle {
  width: 6px;
  height: 6px;
  background-color: var(--chakra-colors-primary) !important;
  border-radius: 100%;
}
.border1px-black {
  border: 1px solid black;
}

.border3px-fff {
  border: 3px solid #fff;
}
.rounded-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}
.rounded-left-lg {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}
.h45px {
  height: 45px !important;
}
input:hover {
  outline: 0px !important;
}
.h-120px {
  height: 120px !important;
}
.backdrop-filer {
  background: rgba(250, 250, 250, 0.5) !important;
  backdrop-filter: blur(10px) !important;
}
.backdrop-filer::placeholder {
  color: #000 !important;
}
// FAQ

.css-186l2rg {
  color: #9d4a4d !important;
}

//filter

.highlightOption {
  background: var(--chakra-colors-secondary) !important;
}

.multiSelectContainer li:hover {
  background: var(--chakra-colors-secondary) !important;
}
.chip {
  background: var(--chakra-colors-secondary) !important;
}
.cerashadow {
  box-shadow: rgba(34, 41, 47, 0.1) 0px 4px 24px 0px !important;
}
.menucheckii .css-r6z5ec {
  width: 100% !important;
  transform: translate3d(0px, 42px, 0px) !important;
  top: 22px !important;
}
.rem-rounded {
  border-radius: 0px !important;
}
// user-card

.minus3rem-mtop {
  margin-top: -3rem;
}

.minus2rem-mtop {
  margin-top: -2rem;
}

.w-10rem {
  width: 10rem;
}
.w-9rem {
  width: 9rem;
}

.px-0px {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bg-f4f4f4 {
  background-color: #f4f4f4;
}

.clr-515151 {
  color: #515151;
}

.clr-6f6f6f {
  color: #6f6f6f;
}

.clr-848484 {
  color: #848484;
}

.clr-ec1c24 {
  color: #ec1c24;
}

.css-1ss3utg[aria-selected="true"],
.css-1ss3utg[data-selected] {
  color: #fff !important;
  background: black !important;
  border-radius: 4px !important;
}
.css-1ss3utg {
  border-radius: 4px !important;
}

.bg-fff {
  background-color: #fff !important;
}

.fsize12px {
  font-size: 12px !important;
}

.maxheight350 {
  max-height: 350px !important;
}

.w-70px {
  width: 70px;
}

.bottom-border-ebebeb {
  border-bottom: 1px solid #ebebeb;
}

.lh-26px {
  line-height: 26px;
}

@media (max-width: 1024px) and (min-width: 641px) {
  .md-fsize18px {
    font-size: 18px !important;
  }

  .md-w-110px {
    width: 110px;
  }

  .md-w-60px {
    width: 60px !important;
  }
}
@media (max-width: 641px) {
  .sm-lh-22px {
    line-height: 22px !important;
  }

  .sm-w-80 {
    width: 80%;
  }

  .sm-drawer-padding {
    padding: 0px 5px 0px 5px !important;
  }

  .sm-ml-8px {
    margin-left: 8px !important;
  }
}
.border6px-fff {
  border: 6px solid #fff;
}

.w-35 {
  width: 35%;
}
.w-65 {
  width: 65%;
}
.min-w-65 {
  @media (min-width: 576px) {
    width: 65% !important;
  }
}
//testimonial
.testimonial .coverflow__text__39hqd {
  display: none !important;
}
.testimonial .coverflow__container__1P-xE {
  height: 145px !important;
  background: none !important;
}
.testimonial .coverflow__figure__3bk_C {
  box-shadow: none !important;
  margin: 0px 0px 0px 30px !important;
  -webkit-box-reflect: unset !important;
  @media (max-width: 640px) {
    width: 80px !important;
  }
}
.testimonial .coverflow__cover__25-7e {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50% !important;
}
.testimonial .coverflow__coverflow__53z9A {
  margin-top: 1px !important;
}
.roundetest {
  @media (min-width: 576px) {
    width: 70px !important;
    height: 70px !important;
  }

  @media (max-width: 575px) {
    width: 50px !important;
    height: 50px !important;
  }
}
.fillstroke {
  stroke: #ffd700 !important;
  fill: #ffd700 !important;
}
.activeuser {
  border: 3px solid white;
}

//plans
.bg-plancard {
  border-radius: 10px;
  backdrop-filter: blur(17.5px);
  background: rgba(255, 255, 255, 0.25);
}
.bg-plancardwhite {
  border-radius: 3px;

  background: #fafafa;
}
.fitcontent {
  width: fit-content !important;
}
.planstop {
  @media (min-width: 576px) {
    margin-top: -140px;
  }

  @media (max-width: 575px) {
    margin-top: -110px;
  }
}
.borderblack {
  border: 1px solid #000;
}
.cardhover {
  transition: transform 0.2s;
}
.cardhover:hover {
  transform: scale(1.1);
  z-index: 99;
}
.active {
  color: var(--chakra-colors-primary);
}
.minmaxh280px {
  @media (min-width: 576px) {
    height: 280px !important;
  }
  @media (max-width: 575px) {
    height: 180px !important;
  }
}
.reactquill-h .ql-container {
  height: 200px;
}
.rwt__tab {
  font-size: 14px !important;
}

// file-uploader

.uploader {
  border: 2px dashed #d9d9d9 !important;
  height: 180px !important;
  width: 100%;
  border-radius: 10px;
}

.uploader-input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 99;
}

.h-180px {
  height: 180px;
}

.h-50px {
  height: 50px !important;
}

.bg-none {
  background: none !important;
}

.bg-fbfbfb {
  background: #fbfbfb !important;
}

.btnn,
.btnn:hover {
  background: none !important;

  font-weight: normal !important;
}

.h-200px {
  height: 200px;
}

.w-120px {
  width: 120px !important;
}

.h-90 {
  height: 90vh !important;
}

.img-fuit {
  width: 95% !important;
  height: 90vh !important;
}

@media (max-width: 641px) {
  .img-fuit {
    width: 100% !important;
    height: 80vh !important;
  }

  .sm-h-80 {
    height: 80vh !important;
  }

  .css-iohxn1 {
    right: 0 !important;
  }
}

.react-calendar {
  width: 100%;
}

.react-calendar__tile--active,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--chakra-colors-primary);
  color: white;
}

.react-calendar__tile--now {
  background: #c2bfbe4f;
}

.hr1 {
  margin: 0px 10px;
  width: 2px;
  opacity: 90;
  min-height: 15px;
  background-color: #9c9c9c;
}

.border-6d6d6d {
  border: 1px solid #6d6d6d;
}
.cust-multiselect .searchWrapper {
  min-height: 45px !important;
  padding: 10px !important;
}
.cust-multiselect .searchWrapper .chip {
  margin-bottom: 4px !important;
}
.zindex_menu .css-r6z5ec {
  z-index: 99 !important;
}
.bordertop {
  border-top: 1px solid #e2e8f0;
}
.drawer-w .css-1o3pyl4 {
  width: 16rem !important;
}

.w-100per {
  width: 100% !important;
}

.radio .css-14iyyou {
  font-size: 14px;
}

.css-1u2cvaz {
  background: #e7e7e75e !important;
}

.border-accordion {
  border-width: 1px !important;
  border-radius: 6px !important;
}

.online-dot {
  width: 8px;
  height: 8px;
  background-color: green;
  border-radius: 100%;
}

.sel .css-161pkch {
  padding: 10px 10px !important;
  font-size: 12px !important;
  height: fit-content !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
}

// find coach

.input-border-none {
  border: none !important;
}

.pd-6px {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.border-t-1 {
  border-top: 1px solid #dfdbda;
}

.border-b-1 {
  border-bottom: 1px solid #dfdbda;
}

.mt-2px {
  margin-top: 2px;
}

.zindex99 {
  z-index: 99;
}
select.mini-select {
  border: 1px solid #80808066;
  border-radius: 999px;
  padding: 2px 5px;
  font-size: 14px;
}
select.mini-select:focus {
  outline: 0px !important;
}

// .poll-bg-ff5c62 {
//   background-color: #ff5c62 !important;
// }
.poll-bg-ff5c62 .css-1jrtelv {
  background-color: #ff5c62 !important;
}

.poll-bg-ff5c62-select .css-1jrtelv {
  background-color: #ff5c62 !important;
  border: 1px solid #9d0309;
}
.active {
  color: var(--chakra-colors-primary) !important;
  font-weight: 600 !important;
}
.react-reveal {
  opacity: 1 !important;
}
.rounded-3px {
  border-radius: 3px;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: var(--chakra-colors-primary) !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: none !important;
}

// .css-x9dxnn{
//   max-width: 80rem !important;
// }

.cust-error-border {
  box-shadow: 0 0 0 1px #e53e3e !important;
  border: 1px solid #e53e3e !important;
  border-radius: 6px !important;
}

// invoice-css

.pdf {
  color: #000 !important;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.lastpdf {
  color: #000 !important;
  border-bottom: none;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.lastpdfg {
  color: #000 !important;
  border: none !important;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.new-pdfg {
  border-bottom: 1px solid #626262 !important;
}
.startpdf {
  color: #000 !important;
  border-left: 1px solid gray;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.nowpdf {
  color: #000 !important;
  border: none;
  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.news {
  width: 280px !important;
  color: #000 !important;
}
.overall-col {
  color: #000 !important;
}
.bc-clr {
  border-color: #626262;
}

.borderfty {
  border-bottom: 2px solid #626262;
}

.borderight {
  border-right: 2px solid #626262;
}

th {
  color: black;
  font-size: 15px;
  font-weight: 600;
}
.isloading-cs {
  position: absolute;
  top: -28px;
}

.h-330px {
  height: 330px;
  @media (max-width: 640px) {
    height: auto;
  }
}
.h-360px {
  height: 360px;
  @media (max-width: 640px) {
    height: auto;
  }
}

.h-230px {
  height: 230px;
  @media (max-width: 640px) {
    height: auto;
  }
}

.lineClamp5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.z-100 {
  z-index: 200;
}

.hov-underline:hover {
  text-decoration: underline;
}

.prog .css-1cqh9jq {
  position: absolute !important;
  top: 4px;
  left: 4px;
  z-index: 100;
  width: 100%;
}

.prog .radio {
  display: none !important;
}

.border-secondary {
  border: 1px solid red;
}

.marg-inline-start {
  margin-inline-start: 0px !important;
}

.height-30rem {
  height: auto;
  min-height: 30rem;
}

.border-primary {
  border: 1px solid var(--chakra-colors-primary) !important;
}

.hoverr:hover {
  background: var(--chakra-colors-secondary) !important;
  color: white !important;
  border: 1px solid var(--chakra-colors-secondary) !important;
}

.cursor-nodrop {
  cursor: no-drop !important;
}

.pagination li {
  width: 35px !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: #f1f1f1 !important;
  border-radius: 50% !important;
  list-style: none !important;
}

.pagination li.selected {
  width: 35px !important;
  height: 35px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background: var(--chakra-colors-primary) !important;
  color: #fff !important;
}

.blogQuill .ql-editor{
  padding: 12px 15px 12px 0px !important;
}

.cust-marquee{
      background: #ed1b2438;
}
.btn-max{
  max-width: 0px !important;
}
.bg-fa{
  background: #f4f5f6 !important;
}
.chatMenu-scroll{
  height: 570px;
  overflow-y: auto;
     
}
.parentChat-scroll{
  height: 570px;
}
.bg-ddd{
  background-color: #ddd;
}
.chat-textscroll{
  height: 418px;
}
.overlays{
  background: #00000028;
}
.chat-bubble {
  background-color: #fff;
  padding: 10px 24px;
  border-radius: 8px;
  display: inline-block;
  margin-bottom: 20px;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #fff; //rgba(20,105,69,.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}
@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #3b82f6; // rgba(20,105,69,.7);
  }
  28% {
    transform: translateY(-7px);
    background-color: #8092ad; //rgba(20,105,69,.4);
  }
  44% {
    transform: translateY(0px);
    background-color: #c2cddf; //rgba(20,105,69,.2);
  }

}
.rounded-4px{
      border-radius: 2px;
}
.usertrue{
  right: -2px;
    bottom: -2px;
}


.bg-blue200{
        background: #bfdbfe !important;
            color: #4b5563 !important;
            height: 42px !important;
            padding: 0.5rem !important;
                border-radius: 0.125rem !important;
}
.infinite-scroll-component{
overflow: visible !important;
}